<template>
  <b-form>
    <b-card title="Doküman Tipi Güncelle">
      <b-form-group
        label="Başlık"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="formData.title"
          placeholder="Başlık"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'DamageDocumentTypeEdit',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      detailForm: false,
      submitStatus: false,
      formData: {
        title: null,
      },
    }
  },
  computed: {
    typeData() {
      return this.$store.getters['damageTrackingDocumentTypes/getData']
    },
    saveStatus() {
      const status = this.$store.getters['damageTrackingDocumentTypes/saveDataStatus']
      return status
    },
  },
  watch: {
    typeData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      this.$store.dispatch('damageTrackingDocumentTypes/dataItem', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('damageTrackingDocumentTypes/saveData', this.formData)
    },
  },
}
</script>
